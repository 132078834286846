<template>
  <footer id="gavc-footer" class="gavc-text-white" style="margin-top: 100px;">

    <div class="container-fluid">
      <div class="row text-center" style="padding-top: 50px;">
        <div class="col-12 col-md-3">
          <div class="footer-main-icon">
            <i class="gavc-icon gavc-icon-expert"></i>
          </div>
          <div class="mt-2">
            Des experts à vos côtés
            <strong>
              pour vous acompagner tout au long de votre vie d'entreprise
            </strong>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="footer-main-icon">
            <i class="gavc-icon gavc-icon-timer"></i>
          </div>
          <div class="mt-2">
            5 minutes
            <strong>
              pour vous inscrire et publier votre annonce !
            </strong>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="footer-main-icon">
            <i class="gavc-icon gavc-icon-computer"></i>
          </div>
          <div class="mt-2">
            Centralisation et vérification
            <strong>
              des candidatures pour vous aider à comparer les repreneurs
            </strong>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="footer-main-icon">
            <i class="gavc-icon gavc-icon-folder"></i>
          </div>
          <div class="mt-2">
            Un autre argument
            <strong>
              pour vous simplifier la vie ?
            </strong>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid" style="background-color: #006F73; margin-top: 50px;">
      <div class="row">
        <div class="col-12">
          <p>
            Suivez-nous
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <ul class="gavc-socials">
            <li class="gavc-socials__item" style="list-style: none !important; margin-left: -40px !important;">
              <a href="https://www.facebook.com/GaranceMutuelle/" target="_blank">
                <i class="gavc-icon gavc-icon-facebook" style="color: white !important;"></i>
              </a>
            </li>
            <li class="gavc-socials__item" style="list-style: none !important">
              <a href="https://twitter.com/garancemutuelle" target="_blank">
                <i class="gavc-icon gavc-icon-twitter" style="color: white !important;"></i>
              </a>
            </li>
            <li class="gavc-socials__item" style="list-style: none !important">
              <a href="https://www.linkedin.com/company/mnra" target="_blank">
                <i class="gavc-icon gavc-icon-linkedin" style="color: white !important;"></i>
              </a>
            </li>
            <li class="gavc-socials__item" style="list-style: none !important">
              <a href="https://www.youtube.com/channel/UCwrRgPWTHEjeoPX69EXDrkQ" target="_blank">
                <i class="gavc-icon gavc-icon-youtube" style="color: white !important;"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <nav id="nav-footer" class="nav-footer" role="navigation">
            <ul class="row">
              <li class="col-md-3 menu-item">
                <span class="footer-title">Accès direct</span>
                <ul>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/epargne/" data-description=""> Gamme épargne </a>
                  </li>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/retraite/" data-description=""> Gamme retraite </a>
                  </li>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/prevoyance/" data-description=""> Gamme prévoyance </a>
                  </li>
                </ul>
              </li>

              <li class="col-md-3 menu-item">
                <span class="footer-title">Tout sur Garance</span>
                <ul>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance-mutuelle.fr/a-propos-de-garance/" data-description=""> A propos</a>
                  </li>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/nos-conseils/" data-description=""> Actualités </a>
                  </li>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/espace-presse/" data-description=""> Presse </a>
                  </li>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/contact/" data-description=""> Nous contacter </a>
                  </li>
                </ul>
              </li>

              <li class="col-md-3 menu-item">
                <span class="footer-title">Liens utiles</span>
                <ul>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/carrieres/" data-description=""> Rejoignez-nous </a>
                  </li>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/reclamation/" data-description=""> Réclamation/ Médiation </a>
                  </li>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance.com/" data-description=""> Plan du site </a>
                  </li>
                  <li style="list-style: none !important">
                    <a
                      target="_blank"
                      href="https://www.garance-a-vos-cotes.fr/mentions-legales-conditions-utilisations/"
                      data-description=""
                    >
                      Mentions légales
                    </a>
                  </li>
                  <li style="list-style: none !important">
                    <a target="_blank" href="https://www.garance-mutuelle.fr/les-contrats-en-desherence/" data-description=""> Les contrats en déshérence </a>
                  </li>
                  <li style="list-style: none !important">
                    <a
                      target="_blank"
                      href="https://www.garance-a-vos-cotes.fr/mentions-legales-conditions-utilisations/"
                      data-description=""
                    >
                      Conditions d'utilisation
                    </a>
                  </li>
                  <li style="list-style: none !important">
                    <a
                      target="_blank"
                      href="https://www.garance-a-vos-cotes.fr/politique-de-confidentialite/"
                      data-description=""
                    >
                      Politique de confidentialité
                    </a>
                  </li>
                </ul>
              </li>

              <li class="col-md-3 menu-item">
                <span class="footer-title">Autres sites et sites partenaires</span>
                <ul>
                  <li style="list-style: none !important">
                    <a
                      target="_blank"
                      href="https://www.garance.com/"
                      data-description=""
                    >
                      Garance
                    </a>
                  </li>
                  <li style="list-style: none !important">
                    <a
                      target="_blank"
                      href="https://www.garance.com/garance-mecenat/"
                      data-description=""
                    >
                      Mécénat by Garance
                    </a>
                  </li>
                </ul>
              </li>

            </ul>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footerComponent',
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

#gavc-footer {
  background-color: $green-darken-1;
}
.footer-img {
  width: 400px;
}


.nav-footer>ul {
  list-style: none !important;
}
.nav-footer>ul>li>ul {
  padding-left: 0 !important;
  list-style: none !important;
}

.nav-footer [href="#"] {
  color: #fff;
  cursor: text;
}

.nav-footer a {
  display: block;
  margin: 15px 0;
  color: $white-lighten-1;
}

.footer-title {
  text-transform: uppercase;
  display: block;
  margin: 15px 0 10px;
  color: #fff;
  font-size: 14px;
}

.footer-main-icon {
  font-size: 6rem;
}
</style>
